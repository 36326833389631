import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore'

var config = {
  apiKey: "AIzaSyDvmW8edD9CEeY2gxEhvOw1uGLG5Ru5ppA",
  authDomain: "rollcall-cf68a.firebaseapp.com",
  databaseURL: "https://rollcall-cf68a.firebaseio.com",
  projectId: "rollcall-cf68a",
  storageBucket: "rollcall-cf68a.appspot.com",
  messagingSenderId: "286497925127"
};

firebase.initializeApp(config);
export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const db = firebase.firestore();
db.settings({timestampsInSnapshots: true});
export default firebase;
