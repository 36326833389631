import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions/DatabaseActions";
import AttendeeListTile from "./AttendeeListTile";
import moment from "moment";
import { CenteredButton } from "../styles/RollCall_styles";
import { StyledButtonDiv } from "../styles/PastRolls_styles";
import { getPdf } from "../actions/PdfActions";
import { base64ToBlob } from "../util";
import FileSaver from "file-saver";

class RollCallAttendeeList extends Component {
  state = {
    isEditing: false,
    isFetching: false,
  };
  updateEvent = (e, attendeeName) => {
    const { selectedEvent } = this.props;
    const { name, value, checked } = e.target;
    const eventData = selectedEvent.data();

    let parsedValue;

    if (name === "attended") {
      parsedValue = checked;
    } else {
      parsedValue = value ? moment(value, "HH:mm").format("hh:mm a") : null;
    }

    const attendeeArray = eventData.attendees.map(a => {
      if (attendeeName === a.name) {
        a[name] = parsedValue;
      }
      return a;
    });
    
    eventData.attendees = attendeeArray;
    this.props.updateEvent(selectedEvent.id, eventData);
  };
  toggleEdit = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };
  downloadPdf = async () => {
    const { selectedEvent, user, selectedTopLevelGroup, selectedSubGroup } = this.props;
    this.setState({ isFetching: true });
    const data = {
      attendees: selectedEvent.data().attendees,
      topLevelGroupName: selectedTopLevelGroup.data().name,
      topLevelGroupDescription: selectedTopLevelGroup.data().description,
      subGroupName: selectedSubGroup.data().name,
      subGroupDescription: selectedSubGroup.data().description,
      eventName: selectedEvent.data().name,
      userName: user.displayName,
    }
    await this.props.getPdf(data);
    this.setState({ isFetching: false });
    const blob = base64ToBlob(this.props.pdf, "application/pdf;charset=utf-8");
    FileSaver.saveAs(blob, `${data.topLevelGroupName}_${data.subGroupName}_${data.eventName.replace(/[ /]/g, '_')}.pdf`);
  };
  reset = () => {
    this.props.clearSelectedEvent();
    this.props.unSelectTopLevelGroup();
    this.props.unSelectSubGroup();
    this.props.clearAllEvents();
  };
  render() {
    const { selectedEvent, pathName } = this.props;
    const { isEditing, isFetching } = this.state;
    return (
      <div>
        {selectedEvent &&
          pathName === "/rollcall" && (
            <CenteredButton type="button" onClick={this.reset}>
              Finished
            </CenteredButton>
          )}
        {selectedEvent &&
          pathName === "/pastrolls" && (
            <StyledButtonDiv>
              <CenteredButton type="button" onClick={this.toggleEdit}>
                {isEditing ? (
                  <i className="far fa-save" />
                ) : (
                  <i className="fas fa-pencil-alt" />
                )}
              </CenteredButton>
              <button type="button" onClick={this.downloadPdf}>
                {isFetching ? <i className="fas fa-spinner fa-pulse" /> : <i className="fas fa-download" />}
              </button>
            </StyledButtonDiv>
          )}

        {selectedEvent &&
          selectedEvent
            .data()
            .attendees.map(
              a =>
                a !== null && (
                  <AttendeeListTile
                    attendeeEvent={a}
                    key={a.name}
                    readOnlyMode={pathName === "/rollcall" ? false : !isEditing}
                    handleChange={this.updateEvent}
                  />
                )
            )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, db, pdf }) => ({
  user: auth.user,
  selectedSubGroup: db.selectedSubGroup,
  selectedTopLevelGroup: db.selectedTopLevelGroup,
  selectedEvent: db.selectedEvent,
  pdf: pdf.pdf
});

export default connect(
  mapStateToProps,
  { ...actions, getPdf }
)(RollCallAttendeeList);
