import {
  GET_TOP_LEVEL_GROUPS,
  GET_TOP_LEVEL_GROUP_BY_ID,
  GET_SUB_GROUPS,
  GET_SUB_GROUP_BY_ID,
  GET_ALL_ATTENDEES,
  CLEAR_ALL_SUB_GROUPS,
  CLEAR_ALL_TOP_LEVEL_GROUPS,
  GET_EVENT_BY_ID,
  CLEAR_SELECTED_EVENT,
  CLEAR_ALL_EVENTS,
  CLEAR_ALL_ATTENDEES,
  GET_EVENTS,
} from "../actions/index";

const initialState = {
  topLevelGroups: null,
  subGroups: null,
  selectedTopLevelGroup: null,
  selectedSubGroup: null,
  attendees: null,
  selectedEvent: null,
  events: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_TOP_LEVEL_GROUPS:
      return { ...state, topLevelGroups: action.payload };
    case GET_TOP_LEVEL_GROUP_BY_ID:
      return { ...state, selectedTopLevelGroup: action.payload };
    case GET_SUB_GROUPS:
      return { ...state, subGroups: action.payload };
    case CLEAR_ALL_SUB_GROUPS:
      return { ...state, subGroups: null };
    case CLEAR_ALL_TOP_LEVEL_GROUPS:
      return { ...state, topLevelGroups: null };
    case GET_SUB_GROUP_BY_ID:
      return { ...state, selectedSubGroup: action.payload };
    case GET_ALL_ATTENDEES:
      return { ...state, attendees: action.payload };
    case CLEAR_ALL_ATTENDEES:
      return { ...state, attendees: null };
    case GET_EVENT_BY_ID:
      return { ...state, selectedEvent: action.payload };
    case CLEAR_SELECTED_EVENT:
      return { ...state, selectedEvent: null };
    case GET_EVENTS:
      return { ...state, events: action.payload };
    case CLEAR_ALL_EVENTS:
      return { ...state, events: null };
    default:
      return state;
  }
}
