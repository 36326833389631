import React, { Component } from "react";
import { connect } from "react-redux";
import ListTile from "./ListTile";
import * as actions from "../actions/DatabaseActions";
import { StyledForm } from "../styles/SetUp_styles";

class AttendeesCreateSelect extends Component {
  state = {
    name: "",
    isEditId: null,
  };
  componentDidMount = () => {
    const { user } = this.props;
    this.unsubAllAttendees = this.props.getAllAttendees(user.uid);
  }
  componentWillUnmount = () => {
    this.unsubAllAttendees && this.unsubAllAttendees();
    this.props.clearAllAttendees();
  }
  
  handleTextChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleClick = doc => {
    const { selectedSubGroup } = this.props;
    if (selectedSubGroup !== null) {
      if (this.isIncluded(doc)) {
        this.props.removeFromAttendeeList(selectedSubGroup, doc);
      } else {
        this.props.addToAttendeeList(selectedSubGroup, doc);
      }
    }
    return;
  };
  isIncluded = doc => {
    if (this.props.selectedSubGroup.data().attendeeList == null) return false;
    return this.props.selectedSubGroup
      .data()
      .attendeeList.includes(doc.data().name);
  };
  sortByName = () => {
    this.props.attendees.sort((a, b) => {
      const aName = a.data().name.toLowerCase();
      const bName = b.data().name.toLowerCase();

      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });
  };
  sortByIncluded = () => {
    this.props.attendees.sort((a, b) => {
      if (this.isIncluded(a) && this.isIncluded(b)) {
        return 0;
      }
      if (this.isIncluded(a) && !this.isIncluded(b)) {
        return -1;
      }
      if (!this.isIncluded(a) && this.isIncluded(b)) {
        return 1;
      }
    });
  };
  createAttendee = e => {
    e.preventDefault();
    const { name, isEditId } = this.state;
    if (!name) return;
    const data = {
      userId: this.props.user.uid,
      name
    };
    if (isEditId) {
      this.props.updateAttendee(isEditId, data);
    } else {
      this.props.createAttendee(data);
    }
    this.setState({ name: "", isEditId: null });
  };
  cancelEdit = () => {
    this.setState({
      name: '',
      isEditId: null,
    })
  }
  handleEdit = doc => {
    this.setState({
      name: doc.data().name,
      isEditId: doc.id
    });
  };
  render() {
    const { attendees, selectedSubGroup } = this.props;
    const { name, isEditId } = this.state;
    if (attendees !== null) {
      this.sortByName();
    }
    if (
      selectedSubGroup !== null &&
      selectedSubGroup.data().attendeeList != null &&
      attendees !== null
    ) {
      this.sortByIncluded();
    }
    return (
      <div>
        <h3>Attendees</h3>
        <StyledForm onSubmit={this.createAttendee}>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={this.handleTextChange}
          />
          <div>
            <button type="button" onClick={this.cancelEdit}>Cancel</button>
            <button type="submit">Submit</button>
          </div>
        </StyledForm>
        {attendees !== null &&
          attendees.map(t => {
            return (
              <ListTile
                doc={t}
                key={t.id}
                editId={isEditId}
                editFunction={this.handleEdit}
                clickFunction={this.handleClick}
                checked={
                  selectedSubGroup !== null &&
                  selectedSubGroup.data().attendeeList != null &&
                  selectedSubGroup.data().attendeeList.includes(t.data().name)
                }
              />
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, db }) => ({
  user: auth.user,
  attendees: db.attendees,
  selectedSubGroup: db.selectedSubGroup,
  selectedTopLevelGroup: db.selectedTopLevelGroup
});

export default connect(
  mapStateToProps,
  actions
)(AttendeesCreateSelect);
