import axios from "axios";
import { GET_PDF } from "./index";

export const getPdf = (data) => dispatch =>
  axios
    .post("https://roll-call-function.azurewebsites.net/api/CreatePdf", data)
    .then(res => {
      dispatch({
        type: GET_PDF,
        payload: res.data
      });
      return res;
    })
    .catch(err => console.log(err.message));
