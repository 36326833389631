import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  StyledAttendeeListTile,
  StyledNameDiv,
  StyledTimeDiv,
  StyledCheckbox
} from "../styles/RollCall_styles";

export class AttendeeListTile extends Component {
  state = {
    attended: false,
    start: null,
    end: null
  };
  render() {
    const { attendeeEvent, handleChange, readOnlyMode } = this.props;
    return (
      <StyledAttendeeListTile>
        <StyledNameDiv>
          <label htmlFor="attended">
            <StyledCheckbox
              type="checkbox"
              checked={attendeeEvent.attended}
              onChange={e => handleChange(e, attendeeEvent.name)}
              name="attended"
              disabled={readOnlyMode}
            />
            {attendeeEvent.name}
          </label>
        </StyledNameDiv>
        <StyledTimeDiv>
          <div>
            <label htmlFor="start">Begin: </label>
            <input
              type="time"
              name="start"
              disabled={readOnlyMode}
              value={
                attendeeEvent.start
                  ? moment(attendeeEvent.start, "hh:mm a").format("HH:mm")
                  : ""
              }
              onChange={e => handleChange(e, attendeeEvent.name)}
            />
          </div>
          <div>
            <label htmlFor="end">Finish: </label>
            <input
              type="time"
              name="end"
              disabled={readOnlyMode}
              value={
                attendeeEvent.end
                  ? moment(attendeeEvent.end, "hh:mm a").format("HH:mm")
                  : ""
              }
              onChange={e => handleChange(e, attendeeEvent.name)}
            />
          </div>
        </StyledTimeDiv>
      </StyledAttendeeListTile>
    );
  }
}

const mapStateToProps = ({ auth, db }) => ({
  user: auth.user,
  selectedEvent: db.selectedEvent
});

export default connect(
  mapStateToProps,
  null
)(AttendeeListTile);
