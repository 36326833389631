import styled from 'styled-components';

export const StyledHeader = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  h1 {
    margin-top: 45px;
    margin-bottom: 0;
    font-size: 36px;

    @media (max-width: 400px) {
      font-size: 24px;
    }
  }
`;

export const StyledHeaderImage = styled.img`
  height: 150px;
  margin-left: 45px;
`;