import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import * as actions from "../actions/DatabaseActions";
import { ExpandingDiv } from "../styles/RollCall_styles";

class EventName extends Component {
  state = {
    name: "",
    isExpanded: false,
    timestamp: null
  };
  // componentWillUnmount = () => {
  //   this.unsubEvent && this.unsubEvent();
  // }
  
  expandDiv = () => {
    if (this.props.selectedEvent) return;
    this.setState({
      isExpanded: !this.state.isExpanded,
      timestamp: this.createTimestamp()
    });
  };
  createEvent = () => {
    const { name, timestamp } = this.state;
    const { selectedTopLevelGroup, selectedSubGroup, user } = this.props;
    if (!selectedSubGroup || !selectedTopLevelGroup) return;
    if (!selectedSubGroup.data().attendeeList) {
      alert('No Attendees Added to Group');
      this.setState({ isExpanded: false, name: "", timestamp: null });
      return;
    }
    const data = {
      name: timestamp + " " + name,
      userId: user.uid,
      createDate: new Date(),
      topLevelDocId: selectedTopLevelGroup.id,
      subGroupDocId: selectedSubGroup.id,
      attendees: this.createAttendees()
    };
    this.unsubEvent && this.unsubEvent();
    this.unsubEvent = this.props.createEvent(data);
    this.setState({ isExpanded: false, name: "", timestamp: null });
  };
  createAttendees = () => {
    const { selectedSubGroup, user } = this.props;
    return selectedSubGroup.data().attendeeList.map(a => ({
      userId: user.uid,
      name: a,
      attended: false,
      end: null,
      start: null
    }));
  };
  createTimestamp = () => {
    return moment().format("MM/DD/YYYY hh:mm a");
  };
  handleTextChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  render() {
    const { name, isExpanded, timestamp } = this.state;
    return (
      <div>
        <button type="button" onClick={this.expandDiv}>
          Start
        </button>
        <ExpandingDiv
          style={isExpanded ? { maxHeight: "200px" } : { maxHeight: "0" }}
        >
          <p>{timestamp || ""}</p>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={this.handleTextChange}
          />
          <button type="button" onClick={this.createEvent}>
            Create
          </button>
        </ExpandingDiv>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, db }) => ({
  user: auth.user,
  selectedTopLevelGroup: db.selectedTopLevelGroup,
  selectedSubGroup: db.selectedSubGroup,
  selectedEvent: db.selectedEvent,
});

export default connect(
  mapStateToProps,
  actions
)(EventName);
