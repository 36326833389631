import React, { Component } from "react";
import { connect } from 'react-redux';
import { BodyWrapper } from "../styles/Page_styles";
import Navigation from "../components/Navigation";
import { ThreeColumnRow } from "../styles/SetUp_styles";
import TopLevelCreateSelect from "../components/TopLevelCreateSelect";
import SubGroupCreateSelect from "../components/SubGroupCreateSelect";
import AttendeesCreateSelect from "../components/AttendeesCreateSelect";
import * as actions from '../actions/DatabaseActions';

class SetUpPage extends Component {
  componentWillUnmount = () => {
    this.props.clearSelectedEvent();
    this.props.unSelectTopLevelGroup();
    this.props.unSelectSubGroup();
  }
  
  render() {
    return (
      <BodyWrapper>
        <Navigation />
        <ThreeColumnRow>
          <TopLevelCreateSelect />
          <SubGroupCreateSelect />
          <AttendeesCreateSelect />
        </ThreeColumnRow>
      </BodyWrapper>
    );
  }
}

export default connect(null, actions)(SetUpPage);
