import { SIGN_OUT, USER } from "./index";

import { auth } from "../firebase";

export const setUser = user => ({
  type: USER,
  payload: user
});

export const signOut = () => dispatch =>
  auth.signOut().then(res =>
    dispatch({
      type: SIGN_OUT
    })
  );
