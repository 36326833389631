import React, { Component } from "react";
import { StyledFooter } from "../styles/Footer_styles";
import { Link } from "react-router-dom";
import img from "../images/Download_on_the_App_Store_Badge.svg";
export default class Footer extends Component {
  render() {
    return (
      <footer>
        <StyledFooter>
          <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
            <div style={{ width: "200px" }}>
              <a href="https://play.google.com/store/apps/details?id=com.brandonlehr.rollcall&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  width="200"
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                />
              </a>
            </div>
            <div style={{ width: '50px' }} />
            <div style={{ width: "170px" }}>
              <a href="https://apps.apple.com/us/app/roll-call-attendance-app/id1472047580">
                <img
                  src={img}
                  alt="Download on the App Store"
                  style={{ width: "100%" }}
                />
              </a>
            </div>
          </div>
          <p>
            &copy; 2018 <a href="brandonlehr.com">Brandon Lehr</a>
          </p>
          <Link to="/privacypolicy">privacy policy</Link>
        </StyledFooter>
      </footer>
    );
  }
}
