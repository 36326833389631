import React, { Component } from "react";
import { connect } from "react-redux";
import { BodyWrapper } from "../styles/Page_styles";
import Navigation from "../components/Navigation";
import TopLevelSelector from "../components/TopLevelSelector";
import SubGroupSelector from "../components/SubGroupSelector";
import { TwoColumnRow, InnerTwoColumnRow } from "../styles/RollCall_styles";
import RollCallAttendeeList from "../components/RollCallAttendeeList";
import EventName from "../components/EventName";

import * as actions from "../actions/DatabaseActions";
import EventListTile from "../components/EventListTile";

export class RollCallPage extends Component {
  componentWillUnmount = () => {
    this.props.clearSelectedEvent();
    this.props.unSelectTopLevelGroup();
    this.props.unSelectSubGroup();
    this.props.clearAllEvents();
  };
  
  render() {
    const {
      selectedEvent
    } = this.props;
    return (
      <BodyWrapper>
        <Navigation />
        <TwoColumnRow>
          <InnerTwoColumnRow>
            <div>
              <TopLevelSelector pathName={this.props.location.pathname} />
              <SubGroupSelector pathName={this.props.location.pathname} />
              {!selectedEvent && <EventName />}
            </div>
            <div>
              {selectedEvent && (
                <EventListTile event={selectedEvent} handleChange={() => {}} />
              )}
            </div>
          </InnerTwoColumnRow>
          <RollCallAttendeeList pathName={this.props.location.pathname} />
        </TwoColumnRow>
      </BodyWrapper>
    );
  }
}

const mapStateToProps = ({ auth, db }) => ({
  user: auth.user,
  selectedTopLevelGroup: db.selectedTopLevelGroup,
  selectedSubGroup: db.selectedSubGroup,
  selectedEvent: db.selectedEvent
});

export default connect(
  mapStateToProps,
  actions
)(RollCallPage);
