import styled from 'styled-components'


export const StyledNav = styled.ul`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #ccc;
  li {
    /* display: inline-block; */
    list-style: none;
    margin-right: 15px;
  }
  button {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
  }
`;