import styled from "styled-components";

export const TwoColumnRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  > div {
    width: 50%;
    min-width: 400px;
    padding: 0 15px;
    border-right: 1px solid #ccc;
    max-height: 800px;
    overflow-y: auto;
    @media (max-width: 950px) {
     border: none;
    }
  }
`;

export const InnerTwoColumnRow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  > div {
    width: 50%;
    min-width: 225px;
    padding: 0 15px;
    max-height: 800px;
    overflow-y: auto;
    @media (max-width: 950px) {
      width: 90%;
    }
  }
`;

export const ExpandingDiv = styled.div`
  overflow: hidden;
  transition: all 500ms;
`;

export const StyledAttendeeListTile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  height: 100px;
  padding: 8px 15px;
  margin: auto;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
`;

export const StyledNameDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTimeDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
    label {
      margin-right: 8px;
    }
  }
`;

export const StyledCheckbox = styled.input`
  margin-right: 8px;
`;

export const CenteredButton = styled.button`
  margin: 10px auto;
  width: 200px;
`;