import {
  SIGN_OUT,
  USER,
} from '../actions/index';



const initialState = {
  user: null,
  authed: false,
};


export default function(state = initialState, action) {
  switch(action.type) {
    case USER:
      return {...state, user: action.payload, authed: true };
    case SIGN_OUT: 
      return { ...state, user: null, authed: false };
    default:
      return state;
  }
}