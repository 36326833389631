import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions/DatabaseActions";

class TopLevelSelector extends Component {
  componentDidMount = () => {
    this.unsubAllTopLevelGroups = this.props.getAllTopLevelGroups(
      this.props.user.uid
    );
  };
  componentWillUnmount = () => {
    this.unsubAllTopLevelGroups && this.unsubAllTopLevelGroups();
    this.unsubTopLevelGroup && this.unsubTopLevelGroup();
    this.unsubAllSubGroups && this.unsubAllSubGroups();
    this.props.clearAllTopLevelGroups();
  };

  handleChange = e => {
    const { value } = e.target;
    const { user } = this.props;
    if (value !== "") {
      const doc = this.getTopLevelGroupDoc(value);
      this.props.unSelectSubGroup();
      this.props.clearAllSubGroups();
      this.props.clearAllEvents();
      this.props.clearSelectedEvent();
      this.unsubTopLevelGroup && this.unsubTopLevelGroup();
      this.unsubAllSubGroups && this.unsubAllSubGroups();
      this.unsubTopLevelGroup = this.props.getTopLevelGroupById(doc.id);
      this.unsubAllSubGroups = this.props.getAllSubGroups(user.uid, doc.id);
    }
  };
  getTopLevelGroupDoc = value => {
    const { topLevelGroups } = this.props;
    const doc = topLevelGroups.filter(t => t.data().name === value);
    return doc[0];
  };
  render() {
    const {
      topLevelGroups,
      selectedTopLevelGroup,
      selectedEvent,
      pathName
    } = this.props;
    return (
      <div>
        <label htmlFor="topLevelName">Top Level Group</label>
        <select
          value={selectedTopLevelGroup ? selectedTopLevelGroup.data().name : ""}
          onChange={this.handleChange}
          name="topLevelName"
          disabled={selectedEvent && pathName !== "/pastrolls"}
        >
          <option value="">Choose</option>
          {topLevelGroups != null &&
            topLevelGroups.map(t => (
              <option key={t.id} value={t.data().name}>
                {t.data().name}
              </option>
            ))}
        </select>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, db }) => ({
  user: auth.user,
  topLevelGroups: db.topLevelGroups,
  selectedTopLevelGroup: db.selectedTopLevelGroup,
  selectedEvent: db.selectedEvent
});

export default connect(
  mapStateToProps,
  actions
)(TopLevelSelector);
