import React from "react";
import { StyledListTile } from "../styles/SetUp_styles";

export default function ListTile({
  doc,
  checked,
  clickFunction,
  editFunction,
  editId
}) {
  return (
    <StyledListTile style={editId === doc.id ? { opacity: ".4" } : {}}>
      <button onClick={() => editFunction(doc)}>
        <i className="fas fa-pencil-alt" />
      </button>
      <div>
        <p>{doc.data().name}</p>
        {doc.data().description && <p>{doc.data().description}</p>}
      </div>
      <input
        type="checkbox"
        checked={checked}
        onChange={() => clickFunction(doc)}
      />
    </StyledListTile>
  );
}
