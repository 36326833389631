import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import storeConfig from "./store";
import Header from "./components/Header";
import Footer from "./components/Footer";
import RequireAuth from "./components/RequireAuth";
import LoginPage from "./pages/LoginPage";
import SetUpPage from "./pages/SetUpPage";
import Settings from "./pages/Settings";
import RollCallPage from "./pages/RollCallPage";
import PastRollsPage from "./pages/PastRollsPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const store = storeConfig();

class App extends Component {
  render() {
    return (
      <div>
        <Provider store={store}>
          <BrowserRouter>
            <span>
              <Header />
              <Switch>
                <Route exact path="/" component={RequireAuth(SetUpPage)} />
                <Route exact path="/login" component={LoginPage} />
                <Route
                  exact
                  path="/rollcall"
                  component={RequireAuth(RollCallPage)}
                />
                <Route
                  exact
                  path="/pastrolls"
                  component={RequireAuth(PastRollsPage)}
                />
                <Route
                  exact
                  path="/settings"
                  component={RequireAuth(Settings)}
                />
                <Route exact path="/privacypolicy" component={PrivacyPolicy} />
              </Switch>
              <Footer />
            </span>
          </BrowserRouter>
        </Provider>
      </div>
    );
  }
}

export default App;
