export const SIGN_OUT = "sign_out";
export const USER = "user";

// get from db
export const GET_TOP_LEVEL_GROUPS = "get_top_level_groups";
export const GET_TOP_LEVEL_GROUP_BY_ID = "get_top_level_group_by_id";
export const GET_SUB_GROUPS = "get_sub_groups";
export const GET_SUB_GROUP_BY_ID = "get_sub_group_by_id";
export const GET_ALL_ATTENDEES = "get_all_attendees";
export const GET_EVENTS = 'get_events';
export const GET_EVENT_BY_ID = 'get_event_by_id';


// redux store
export const SELECT_TOP_LEVEL = "select_top_level";
export const SELECT_SUB_GROUP = "select_sub_group";

// clear values
export const CLEAR_SELECTED_EVENT = 'clear_selected_event';
export const CLEAR_ALL_EVENTS = 'clear_all_events';
export const CLEAR_ALL_SUB_GROUPS = "clear_all_sub_groups";
export const CLEAR_ALL_TOP_LEVEL_GROUPS = "clear_all_top_level_groups";
export const CLEAR_ALL_ATTENDEES = 'clear_all_attendees';

export const GET_PDF = 'get_pdf';



