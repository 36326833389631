import { combineReducers } from "redux";
// import { reducer as formReducer } from 'redux-form';
import AuthReducer from './AuthReducer';
import DatabaseReducer from './DatabaseReducer';
import PdfReducer from './PdfReducer';



const rootReducer = combineReducers({
  auth: AuthReducer,
  db: DatabaseReducer,
  pdf: PdfReducer,
 });
 
 export default rootReducer;