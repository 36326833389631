import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

export default function(ComposedComponent, permittedRoles = []) {
  class RequireAuth extends Component {
    
    render() {
      if (!this.props.user) {
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { initialRequest: this.props.location }
            }}
          />
        );
      }
      return <ComposedComponent {...this.props} />;
    }
  }

  const mapStateToProps = ({ auth }) => ({
    user: auth.user,
    authed: auth.authed,
  })

  return withRouter(
    connect(
      mapStateToProps,
      null
    )(RequireAuth)
  );
}
