import React, { Component } from "react";
import { connect } from "react-redux";
import ListTile from "./ListTile";
import * as actions from "../actions/DatabaseActions";
import { StyledForm } from "../styles/SetUp_styles";

class SubGroupCreateSelect extends Component {
  state = {
    name: "",
    description: "",
    isEditId: null
  };
  componentWillUnmount = () => {
    this.unsubSubGroup && this.unsubSubGroup();
  };

  handleTextChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleClick = doc => {
    const { selectedSubGroup } = this.props;
    if (selectedSubGroup !== null && selectedSubGroup.id === doc.id) {
      this.props.unSelectSubGroup();
    } else {
      this.unsubSubGroup && this.unsubSubGroup();
      this.unsubSubGroup = this.props.getSubGroupById(doc.id);
    }
  };
  createSubGroup = e => {
    e.preventDefault();
    const { name, description, isEditId } = this.state;
    const { selectedTopLevelGroup } = this.props;
    if (!name || !description || !selectedTopLevelGroup) return;
    const data = {
      userId: this.props.user.uid,
      topLevelDocId: selectedTopLevelGroup.id,
      name,
      description
    };

    if (isEditId) {
      this.props.updateSubGroup(isEditId, data);
    } else {
      this.props.createSubGroup(data);
    }
    this.setState({ name: "", description: "", isEditId: null });
  };
  cancelEdit = () => {
    this.setState({
      name: "",
      description: "",
      isEditId: null
    });
  };
  handleEdit = doc => {
    this.setState({
      name: doc.data().name,
      description: doc.data().description,
      isEditId: doc.id
    });
  };
  render() {
    const { subGroups, selectedSubGroup } = this.props;
    const { name, description, isEditId } = this.state;
    return (
      <div>
        <h3>Sub Groups</h3>
        <StyledForm onSubmit={this.createSubGroup}>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={this.handleTextChange}
          />
          <label htmlFor="description">Description</label>
          <input
            type="text"
            name="description"
            value={description}
            onChange={this.handleTextChange}
          />
          <div>
            <button type="button" onClick={this.cancelEdit}>
              Cancel
            </button>
            <button type="submit">Submit</button>
          </div>
        </StyledForm>
        {subGroups !== null &&
          subGroups.map(t => {
            return (
              <ListTile
                doc={t}
                key={t.id}
                editId={isEditId}
                editFunction={this.handleEdit}
                clickFunction={this.handleClick}
                checked={
                  selectedSubGroup !== null && t.id === selectedSubGroup.id
                }
              />
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, db }) => ({
  user: auth.user,
  subGroups: db.subGroups,
  selectedSubGroup: db.selectedSubGroup,
  selectedTopLevelGroup: db.selectedTopLevelGroup
});

export default connect(
  mapStateToProps,
  actions
)(SubGroupCreateSelect);
