import {
  GET_TOP_LEVEL_GROUPS,
  GET_TOP_LEVEL_GROUP_BY_ID,
  GET_SUB_GROUPS,
  GET_SUB_GROUP_BY_ID,
  GET_ALL_ATTENDEES,
  CLEAR_ALL_SUB_GROUPS,
  GET_EVENT_BY_ID,
  CLEAR_SELECTED_EVENT,
  GET_EVENTS,
  CLEAR_ALL_EVENTS,
  CLEAR_ALL_TOP_LEVEL_GROUPS,
  CLEAR_ALL_ATTENDEES,
  GET_PDF,
} from "./index";

import { db } from "../firebase";

export const getAllTopLevelGroups = userId => dispatch => {
  const unsubscribe = db
    .collection("top_level_group")
    .where("userId", "==", userId)
    .onSnapshot(querySnapshot => {
      dispatch({
        type: GET_TOP_LEVEL_GROUPS,
        payload: querySnapshot.docs
      });
    });
  return unsubscribe;
};

export const getTopLevelGroupById = id => dispatch => {
  const unsubscribe = db
    .collection("top_level_group")
    .doc(id)
    .onSnapshot(doc => {
      dispatch({
        type: GET_TOP_LEVEL_GROUP_BY_ID,
        payload: doc
      });
    });
  return unsubscribe;
};

export const unSelectTopLevelGroup = () => ({
  type: GET_TOP_LEVEL_GROUP_BY_ID,
  payload: null
});

export const unSelectSubGroup = () => ({
  type: GET_SUB_GROUP_BY_ID,
  payload: null
});

export const clearAllSubGroups = () => ({
  type: CLEAR_ALL_SUB_GROUPS
});

export const clearAllTopLevelGroups = () => ({
  type: CLEAR_ALL_TOP_LEVEL_GROUPS
});

export const clearAllAttendees = () => ({
  type: CLEAR_ALL_ATTENDEES
});

export const getAllSubGroups = (userId, topLevelDocId) => dispatch => {
  const unsubscribe = db
    .collection("sub_group")
    .where("userId", "==", userId)
    .where("topLevelDocId", "==", topLevelDocId)
    .onSnapshot(querySnapshot => {
      dispatch({
        type: GET_SUB_GROUPS,
        payload: querySnapshot.docs
      });
    });
  return unsubscribe;
};

export const getSubGroupById = id => dispatch => {
  const unsubscribe = db
    .collection("sub_group")
    .doc(id)
    .onSnapshot(doc => {
      dispatch({
        type: GET_SUB_GROUP_BY_ID,
        payload: doc
      });
    });
  return unsubscribe;
};

export const getAllAttendees = userId => dispatch => {
  const unsubscribe = db
    .collection("attendee")
    .where("userId", "==", userId)
    .onSnapshot(querySnapshot => {
      dispatch({
        type: GET_ALL_ATTENDEES,
        payload: querySnapshot.docs
      });
    });
  return unsubscribe;
};

export const addToAttendeeList = (
  selectedSubGroup,
  attendeeDoc
) => dispatch => {
  let newAttendeeList = [];
  if (selectedSubGroup.data().attendeeList) {
    newAttendeeList = selectedSubGroup.data().attendeeList;
  }
  newAttendeeList.push(attendeeDoc.data().name);
  db.collection("sub_group")
    .doc(selectedSubGroup.id)
    .update({
      attendeeList: newAttendeeList
    });
};

export const removeFromAttendeeList = (
  selectedSubGroup,
  attendeeDoc
) => dispatch => {
  let newAttendeeList = [];
  if (selectedSubGroup.data().attendeeList) {
    newAttendeeList = selectedSubGroup.data().attendeeList;
  }
  newAttendeeList = newAttendeeList.filter(n => n !== attendeeDoc.data().name);
  db.collection("sub_group")
    .doc(selectedSubGroup.id)
    .update({
      attendeeList: newAttendeeList
    });
};

export const createTopLevelGroup = data => dispatch =>
  db.collection("top_level_group").add(data);

export const updateTopLevelGroup = (docId, data) => dispatch =>
  db
    .collection("top_level_group")
    .doc(docId)
    .update(data);

export const createSubGroup = data => dispatch =>
  db.collection("sub_group").add(data);

export const updateSubGroup = (docId, data) => dispatch =>
  db
    .collection("sub_group")
    .doc(docId)
    .update(data);

export const createAttendee = data => dispatch =>
  db.collection("attendee").add(data);

export const updateAttendee = (docId, data) => dispatch =>
  db
    .collection("attendee")
    .doc(docId)
    .update(data);

export const createEvent = data => dispatch => {
  const docRef = db.collection("event").doc();
  docRef.set(data);

  const unsubscribe = docRef.onSnapshot(doc => {
    dispatch({
      type: GET_EVENT_BY_ID,
      payload: doc
    });
  });
  return unsubscribe;
};

export const getEventById = id => dispatch => {
  const unsubscribe = db
    .collection("event")
    .doc(id)
    .onSnapshot(doc => {
      dispatch({
        type: GET_EVENT_BY_ID,
        payload: doc
      });
    });
  return unsubscribe;
};

export const updateEvent = (docId, data) => dispatch =>
  db
    .collection("event")
    .doc(docId)
    .update(data);

export const clearSelectedEvent = () => ({
  type: CLEAR_SELECTED_EVENT
});

export const clearAllEvents = () => ({
  type: CLEAR_ALL_EVENTS
});

export const getEvents = (userId, subGroupDocId) => dispatch => {
  const unsubscribe = db
    .collection("event")
    .where("userId", "==", userId)
    .where("subGroupDocId", "==", subGroupDocId)
    .onSnapshot(querySnapshot => {
      dispatch({
        type: GET_EVENTS,
        payload: querySnapshot.docs
      });
    });
  return unsubscribe;
};
