import React, { Component } from "react";
import { connect } from "react-redux";
import ListTile from "./ListTile";
import * as actions from "../actions/DatabaseActions";
import { StyledForm } from "../styles/SetUp_styles";

class TopLevelCreateSelect extends Component {
  state = {
    name: "",
    description: "",
    isEditId: null
  };
  componentDidMount = () => {
    const { user } = this.props;
    this.unsubscribeTopLevelGroups = this.props.getAllTopLevelGroups(user.uid);
  };
  componentWillUnmount = () => {
    // unsubscribe
    this.unsubscribeTopLevelGroups();
    this.unsubALLSG && this.unsubALLSG();
    this.unsubTLG && this.unsubTLG();
    // clear redux
    this.props.clearAllTopLevelGroups();
    this.props.unSelectTopLevelGroup();
    this.props.unSelectSubGroup();
    this.props.clearAllSubGroups();
  };

  handleTextChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleClick = doc => {
    const { selectedTopLevelGroup, user } = this.props;
    if (selectedTopLevelGroup !== null && selectedTopLevelGroup.id === doc.id) {
      this.props.unSelectTopLevelGroup();
      this.props.unSelectSubGroup();
      this.props.clearAllSubGroups();
    } else {
      this.props.unSelectSubGroup();
      this.props.clearAllSubGroups();
      this.unsubALLSG && this.unsubALLSG();
      this.unsubTLG && this.unsubTLG();
      this.unsubTLG = this.props.getTopLevelGroupById(doc.id);
      this.unsubALLSG = this.props.getAllSubGroups(user.uid, doc.id);
    }
  };
  createTopLevelGroup = e => {
    e.preventDefault();
    const { name, description, isEditId } = this.state;
    if (!name || !description) return;
    const data = {
      userId: this.props.user.uid,
      name,
      description
    };
    if (isEditId) {
      this.props.updateTopLevelGroup(isEditId, data);
    } else {
      this.props.createTopLevelGroup(data);
    }
    this.setState({ name: "", description: "", isEditId: null });
  };
  cancelEdit = () => {
    this.setState({
      name: "",
      description: "",
      isEditId: null
    });
  };
  handleEdit = doc => {
    this.setState({
      name: doc.data().name,
      description: doc.data().description,
      isEditId: doc.id
    });
  };
  render() {
    const { topLevelGroups, selectedTopLevelGroup } = this.props;
    const { name, description, isEditId } = this.state;
    return (
      <div>
        <h3>Top Level Groups</h3>
        <StyledForm onSubmit={this.createTopLevelGroup}>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={this.handleTextChange}
          />
          <label htmlFor="description">Description</label>
          <input
            type="text"
            name="description"
            value={description}
            onChange={this.handleTextChange}
          />
          <div>
            <button type="button" onClick={this.cancelEdit}>
              Cancel
            </button>
            <button type="submit">Submit</button>
          </div>
        </StyledForm>
        {topLevelGroups !== null &&
          topLevelGroups.map(t => {
            return (
              <ListTile
                doc={t}
                key={t.id}
                editId={isEditId}
                editFunction={this.handleEdit}
                clickFunction={this.handleClick}
                checked={
                  selectedTopLevelGroup !== null &&
                  t.id === selectedTopLevelGroup.id
                }
              />
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, db }) => ({
  user: auth.user,
  topLevelGroups: db.topLevelGroups,
  selectedTopLevelGroup: db.selectedTopLevelGroup
});

export default connect(
  mapStateToProps,
  actions
)(TopLevelCreateSelect);
