import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions/DatabaseActions";

class SubGroupSelector extends Component {
  componentWillUnmount = () => {
    this.unsubSubGroup && this.unsubSubGroup();
    this.unsubAllEvents && this.unsubAllEvents();
    this.props.clearAllSubGroups();
  }
  
  handleChange = e => {
    const { value } = e.target;
    if (value !== "") {
      const doc = this.getSubGroupDoc(value);
      this.unsubSubGroup && this.unsubSubGroup();
      this.unsubSubGroup = this.props.getSubGroupById(doc.id);
      if (this.props.pathName === '/pastrolls') {
        this.unsubAllEvents && this.unsubAllEvents();
        this.unsubAllEvents = this.props.getEvents(this.props.user.uid, doc.id);
        this.props.clearSelectedEvent();
      }
    }
  };
  getSubGroupDoc = (value) => {
    const { subGroups } = this.props;
    const doc = subGroups.filter(t => t.data().name === value);
    return doc[0];
  }
  render() {
    const { selectedSubGroup, subGroups, selectedEvent, pathName } = this.props;
    return (
      <div style={{ marginBottom: '15px' }}>
        <label htmlFor="subGroupName">Sub Group</label>
        <select
          value={selectedSubGroup ? selectedSubGroup.data().name : ""}
          onChange={this.handleChange}
          name="subGroupName"
          disabled={selectedEvent && pathName !== '/pastrolls'}
        >
          <option value="">Choose</option>
          {subGroups != null &&
            subGroups.map(t => (
              <option key={t.id} value={t.data().name}>
                {t.data().name}
              </option>
            ))}
        </select>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, db }) => ({
  user: auth.user,
  subGroups: db.subGroups,
  selectedSubGroup: db.selectedSubGroup,
  selectedEvent: db.selectedEvent,
});

export default connect(
  mapStateToProps,
  actions
)(SubGroupSelector);
