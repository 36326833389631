import React, { Component } from "react";
import { connect } from 'react-redux';
import { BodyWrapper } from "../styles/Page_styles";
import Navigation from "../components/Navigation";
import { CenteredButton } from "../styles/RollCall_styles";
import * as actions from '../actions/AuthActions';
import { auth, provider } from "../firebase";

import { db } from "../firebase";

class Settings extends Component {
  state = {
    showDelete: false,
  }
  login = async () => {
    auth.signInWithPopup(provider).then(async (result) => {
      const user = result.user;
      if (user) {
        var attendees = await db
          .collection("attendee")
          .where("userId", "==", user.uid).get()
        attendees.forEach(d => {
          d.ref.delete()
        })
        var events = await db
          .collection("event")
          .where("userId", "==", user.uid).get()
        events.forEach(d => {
          d.ref.delete()
        })

        var subs = await db
          .collection("sub_group")
          .where("userId", "==", user.uid).get()
        subs.forEach(d => {
          d.ref.delete()
        })

        var tops = await db
          .collection("top_level_group")
          .where("userId", "==", user.uid).get()
        tops.forEach(d => {
          d.ref.delete()
        })

        user.delete()
        this.props.signOut()
      }

    });
  };

  render() {
    return (
      <BodyWrapper>
        <Navigation />
        <h4 style={{ textAlign: 'center' }}>Deleting your account will <span style={{ color: 'red' }}>ERASE ALL DATA</span> and remove account</h4>
        <CenteredButton style={{ color: 'red' }} onClick={() => this.setState({
          showDelete: !this.state.showDelete,
        })}>Delete Account</CenteredButton>
        {
          this.state.showDelete && (
            <div>
              <p style={{ textAlign: 'center', color: 'red', fontWeight: 'bold' }}>Please re-authorize to complete this request.</p>
              <CenteredButton onClick={this.login}>Re-Authorize</CenteredButton>
            </div>
          )
        }
      </BodyWrapper>
    );
  }
}

export default connect(null, actions)(Settings);
