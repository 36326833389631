import { GET_PDF } from '../actions';

const initialState = {
  pdf: null,
}

export default function(state = initialState, action) {
  switch(action.type) {
    case GET_PDF:
      return { ...state, pdf: action.payload };
    default:
      return state;
  }
}