import React, { Component } from "react";
import { connect } from "react-redux";
import { BodyWrapper } from "../styles/Page_styles";
import { auth, provider } from "../firebase";
import * as actions from "../actions/AuthActions";
import { CenteredButton } from "../styles/RollCall_styles";
import img from '../images/Download_on_the_App_Store_Badge.svg';

class LoginPage extends Component {
  componentDidMount() {
    auth.onAuthStateChanged(authUser => {
      if (authUser !== null) {
        this.props.setUser(authUser);
        this.props.history.push("/rollcall");
      }
    });
  }
  login = () => {
    auth.signInWithPopup(provider).then(result => {
      const user = result.user;
      this.setState({
        user
      });
    });
  };
  render() {
    return (
      <BodyWrapper>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h2>Attendance Tracking, Made Easy</h2>
          <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
            <div style={{ width: "200px" }}>
              <a href="https://play.google.com/store/apps/details?id=com.brandonlehr.rollcall&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  width="200"
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                />
              </a>
            </div>
            <div style={{ width: '50px' }} />
            <div style={{ width: "170px" }}>
              <a href="https://apps.apple.com/us/app/roll-call-attendance-app/id1472047580">
                <img
                  src={img}
                  alt="Download on the App Store"
                  style={{ width: "100%" }}
                />
              </a>
            </div>
          </div>
         
          <h4>A Simple Approach for Tracking Attendance</h4>
          <ul>
            <li>Create Top Level Groups (Algebra 101, Troop 104, etc.)</li>
            <li>Create SubGroups (1st Period, Thursday Meetings, etc.)</li>
            <li>Attach attendees to SubGroups</li>
            <li>Take Attendance!</li>
          </ul>

          <p>Attendance Records can be downloaded as PDFs!</p>
          <CenteredButton onClick={this.login}>Sign in</CenteredButton>
        </div>
      </BodyWrapper>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
  authed: auth.authed
});

export default connect(
  mapStateToProps,
  actions
)(LoginPage);
