import React, { Component } from 'react'
import { StyledHeader, StyledHeaderImage } from '../styles/Header_styles';
import  headerImage from '../images/roll_call_banner_SIDE.png';


export default class Header extends Component {
  render() {
    return (
      <header>
        <StyledHeader>
          <h1>Roll Call</h1>
          <StyledHeaderImage src={headerImage} alt="logo"/>
        </StyledHeader>
      </header>
    )
  }
}
