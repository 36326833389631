import { compose, createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import rootReducers from "./reducers";

const storeConfig = () => {
  const applyMiddlewares = applyMiddleware(reduxThunk);

  const createStoreWithMiddleware = compose(
    applyMiddlewares,
    window.devToolsExtension ? window.devToolsExtension() : f => f
  );

  const store = createStoreWithMiddleware(createStore)(rootReducers);


  return store;
};

export default storeConfig;