import styled from "styled-components";

export const ThreeColumnRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%
  > div {
    width: 33%;
    min-width: 300px;
    border-right: 1px solid #ccc;
    max-height: 800px;
    padding-bottom: 20px;
    overflow-y: auto;
    @media (max-width: 950px) {
      border: none;
    }
    h3 {
      text-align: center;
    }
  }
`;

export const StyledListTile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding: 8px 15px;
  margin: auto;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  button {
    border: none;
    padding: none;
  }
  div {
    display: flex;
    flex-direction: column;
    p {
      margin: 0 0 5px 0;
    }
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  margin-bottom: 8px;
  input[type="text"] {
    width: 100%;
  }
  div {
    display: flex;
    justify-content: space-around;
  }
`;
