import React, { Component } from "react";
import { connect } from "react-redux";
import { StyledEventListTile } from "../styles/PastRolls_styles";

class EventListTile extends Component {
  render() {
    const {
      event,
      selectedEvent,
      handleChange,
      selectedSubGroup,
      selectedTopLevelGroup
    } = this.props;
    return (
      <StyledEventListTile>
        <div>
          <p>{event.data().name}</p>
          <p>
            {selectedTopLevelGroup.data().name}: {selectedSubGroup.data().name}{" "}
          </p>
        </div>
          <input
            type="checkbox"
            name="event"
            checked={selectedEvent && selectedEvent.id === event.id}
            onChange={() => handleChange(event)}
          />
      </StyledEventListTile>
    );
  }
}

const mapStateToProps = ({ auth, db }) => ({
  selectedEvent: db.selectedEvent,
  selectedSubGroup: db.selectedSubGroup,
  selectedTopLevelGroup: db.selectedTopLevelGroup
});

export default connect(
  mapStateToProps,
  null
)(EventListTile);
