import React, { Component } from 'react'
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { StyledNav } from '../styles/Navigation_styles';
import { signOut } from "../actions/AuthActions";

class Navigation extends Component {
  render() {
    return (
      <nav>
        <StyledNav>
          <li><NavLink activeClassName="activeNavLink" exact to="/" >SetUp</NavLink></li>
          <li><NavLink activeClassName="activeNavLink" exact to="/rollcall" >Roll Call</NavLink></li>
          <li><NavLink activeClassName="activeNavLink" exact to="/pastrolls" >Past Rolls</NavLink></li>
          {this.props.user && <li><NavLink activeClassName="activeNavLink" exact to="/settings" >Settings</NavLink></li>}
          {this.props.user && <li><button onClick={this.props.signOut} >Sign Out</button></li>}

        </StyledNav>
      </nav>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
  authed: auth.authed,
})

export default connect(mapStateToProps, { signOut })(Navigation);
