import React, { Component } from "react";
import { connect } from "react-redux";
import EventListTile from "./EventListTile";
import * as actions from "../actions/DatabaseActions";

class EventList extends Component {
  componentWillUnmount = () => {
    this.unsubEvent && this.unsubEvent();
  };

  handleEventSelect = event => {
    this.unsubEvent && this.unsubEvent();
    this.unsubEvent = this.props.getEventById(event.id);
  };
  sortByDate = () => {
    const { events } = this.props;
    events.sort((a, b) => {
      const aDate = a.data().createDate.toDate();
      const bDate = b.data().createDate.toDate();
      return bDate - aDate;
    })
  }
  render() {
    const { events } = this.props;
    events && this.sortByDate()
    return (
      <div style={{ paddingTop: "10px" }}>
        {events && 
          events.map(ev => (
            <EventListTile
              key={ev.id}
              event={ev}
              handleChange={this.handleEventSelect}
            />
          ))}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, db }) => ({
  user: auth.user,
  events: db.events
});

export default connect(
  mapStateToProps,
  actions
)(EventList);
